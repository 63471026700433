@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Merriweather", sans-serif;
        color: #282828;
    }
}

@layer components {
    .btn-primary {
        @apply py-3.5 px-7 bg-buttonPrimary text-brandSecondary font-base font-black text-base border-buttonPrimary border hover:bg-brandSecondary hover:text-buttonHover;
    }

    .btn-secondary {
        @apply py-3.5 px-7 bg-brandSecondary text-buttonPrimary font-base font-black text-base border-buttonPrimary border hover:bg-buttonHover hover:text-brandSecondary;
    }

    .btn-login {
        @apply py-3 px-11 gap-x-2 mt-2 bg-buttonPrimary font-base text-brandSecondary font-black text-lg w-fit flex border border-buttonSecondary hover:bg-brandSecondary hover:text-buttonHover;
    }

    .btn-search {
        @apply bg-brandSecondary w-16 h-10 text-white text-base;
    }

    .login-input {
        @apply w-[350px] border border-buttonSecondary p-3 font-base outline-none focus:border-brandSecondary;
    }

    .text-input {
        @apply border border-buttonSecondary p-3 font-base outline-none focus:border-brandSecondary;
    }

    .link-primary {
        @apply cursor-pointer text-brandSecondary underline hover:text-linkHover
    }

    .menu-link {
        @apply font-base bg-white border-brandSecondary border-2 mx-2.5 px-4 py-2 font-bold uppercase text-sm flex hover:bg-brandPrimary hover:text-brandSecondary
    }

    .menu-link.active {
        @apply font-base bg-brandSecondary text-white fill-white hover:bg-buttonHover hover:text-brandSecondary
    }

    .heading {
        @apply w-full py-20 flex justify-between h-10 items-center
    }
}

.navBar button {
    z-index: 10;
    cursor: pointer;
}

.menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 61px;
    background: white;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    z-index: 9;
}

.menuNav.showMenu {
    width: 100%;
}

input::placeholder, select {
    font-weight: 700;
    font-family: "Merriweather", sans-serif;
}

/*#region Fonts*/
/* Font Awesome 6 Free */
@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./scss/fonts/FontAwesome6Free-Regular.woff2') format('woff2'),
    url('./scss/fonts/FontAwesome6Free-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Font Awesome 6 Free';
    src: url('./scss/fonts/FontAwesome6Free-Solid.woff2') format('woff2'),
    url('./scss/fonts/FontAwesome6Free-Solid.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Oswald */
@font-face {
    font-family: 'Oswald';
    src: url('./scss/fonts/Oswald-Bold.woff2') format('woff2'),
    url('./scss/fonts/Oswald-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./scss/fonts/Oswald-ExtraLight.woff2') format('woff2'),
    url('./scss/fonts/Oswald-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./scss/fonts/Oswald-Light.woff2') format('woff2'),
    url('./scss/fonts/Oswald-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald Medium';
    src: url('./scss/fonts/Oswald-Medium.woff2') format('woff2'),
    url('./scss/fonts/Oswald-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./scss/fonts/Oswald-Regular.woff2') format('woff2'),
    url('./scss/fonts/Oswald-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('./scss/fonts/Oswald-SemiBold.woff2') format('woff2'),
    url('./scss/fonts/Oswald-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Merriweather */
@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-Black.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-BlackItalic.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-Bold.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-BoldItalic.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-Italic.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-Light.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-LightItalic.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Merriweather';
    src: url('./scss/fonts/Merriweather-Regular.woff2') format('woff2'),
    url('./scss/fonts/Merriweather-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*#endregion Fonts*/