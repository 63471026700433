.background {
  background: #ffffff;
  background-repeat: repeat;
  background-size: auto;
  background-position: center center;
  min-height: calc(100vh - 64px);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}